import './component.scss'

document.addEventListener('DOMContentLoaded', () => {
  const tabItems = document.querySelectorAll('.refosumu-v2-helpful-column__tab')
  const columnLists = document.querySelectorAll('.refosumu-v2-helpful-column__column-list')
  const moreInfoLink = document.querySelector('.refosumu-v2-helpful-column__more-info-link')
  const moreInfoText = moreInfoLink.querySelector('.refosumu-v2-helpful-column__more-info-text')
  const baseUrl = moreInfoLink.dataset.baseUrl

  // 初期表示時のURLを設定（お風呂）
  moreInfoLink.href = `${baseUrl}mizumawari_bath/`

  // 各タブにクリックイベントを設定
  tabItems.forEach(tab => {
    tab.addEventListener('click', () => {
      // アクティブなタブのスタイルを切り替え
      tabItems.forEach(t => t.classList.remove('refosumu-v2-helpful-column__tab--active'))
      tab.classList.add('refosumu-v2-helpful-column__tab--active')

      // 選択されたタブに対応するコンテンツを表示
      const tabType = getTabType(tab)
      columnLists.forEach(list => {
        if (list.classList.contains(`${tabType}-columns`)) {
          list.setAttribute('data-visible', 'true') // 対応するコンテンツを表示
        } else {
          list.setAttribute('data-visible', 'false') // 他のコンテンツを非表示
        }
      })

      // 「もっと詳しく」セクションの更新
      const categoryText = getCategoryText(tabType)
      moreInfoText.textContent = `${categoryText}リフォームをもっと詳しく`
      moreInfoLink.href = `${baseUrl}${tabType}/`
    })
  })
})

/**
 * タブの種類を特定する関数
 */
function getTabType (tab) {
  const iconElement = tab.querySelector('.refosumu-v2-helpful-column__tab-icon')
  // アイコンのクラス名からタブの種類を判定
  if (iconElement.classList.contains('refosumu-v2-helpful-column__tab-icon--bath')) return 'mizumawari_bath'
  if (iconElement.classList.contains('refosumu-v2-helpful-column__tab-icon--kitchen')) return 'mizumawari_kitchen'
  if (iconElement.classList.contains('refosumu-v2-helpful-column__tab-icon--lavatory')) return 'mizumawari_lavatory'
  if (iconElement.classList.contains('refosumu-v2-helpful-column__tab-icon--toilet')) return 'mizumawari_toilet'
  return 'mizumawari_bath' // デフォルトはお風呂
}

/**
 * タブの種類から表示用のカテゴリー名を取得する関数
 */
function getCategoryText (tabType) {
  // タブの種類と表示名のマッピング
  const categoryMap = {
    mizumawari_bath: 'お風呂',
    mizumawari_kitchen: 'キッチン',
    mizumawari_lavatory: '洗面台',
    mizumawari_toilet: 'トイレ'
  }
  return categoryMap[tabType]
}
