import 'core-js/stable'
import 'regenerator-runtime/runtime'
import * as $ from 'jquery'
import 'lazysizes'

import '../../src/util/install_sentry'
import 'Components/refosumu/site_footer/component'
import 'Components/refosumu/site_header/component'
import 'Components/refosumu/site_header_navigation/component'
import 'Components/refosumu/v2/main_visual/component'
import 'Components/refosumu/v2/reform_parts/component'
import 'Components/refosumu/v2/helpful_column/component'
import 'Components/refosumu/v2/construction_interview/component'
import 'Components/refosumu/v2/feature/component'
import 'Components/refosumu/v2/partner_companies/component'
import 'Components/refosumu/v2/flow/component'
import 'Components/refosumu/v2/subsidy/component'
import 'Components/refosumu/v2/national_subsidies/component'
import 'Components/refosumu/v2/service_renewal/component'
import 'Components/refosumu/v2/faqs/component'
import 'Components/refosumu/v2/floating/component'
import 'Components/refosumu/v2/blue_dotted_line/component'
import 'Components/refosumu/v2/simple_button/component'
import 'Components/refosumu/v2/yellow_dotted_line/component'
import 'Components/refosumu/v2/title_board/component'
import 'Components/refosumu/v2/green_dotted_line/component'
import spToggleNav from '../../src/spToggleNav/spToggleNav'

$(() => {
  spToggleNav()
})
