import './component.scss'

import './images/blue_line.png'
import './images/refosumu_logo.svg'
import './images/telephone_icon.svg'
import './images/mail_icon.svg'
import './images/link_list_arrow_icon.svg'
import './images/company_logo.png'
import './images/jpx.png'
import './images/yellow_square_icon.svg'
import 'Images/telephone_number.svg'
import 'Images/free_call_icon.svg'
import '@stimulus/polyfills'
import { Application, Controller } from 'stimulus'

const $ = require('jquery')

const app = Application.start()

app.register('footer-accordion', class extends Controller {
  initialize () {
    this.addDataAction()
  }

  addDataAction () {
    const accordions = document.getElementsByClassName('js-footer-sub-category-open-trigger')
    Array.from(accordions).forEach(accordion => accordion.dataset.action = 'click->footer-accordion#toggleAccordion')
  }

  toggleAccordion (e) {
    $(e.currentTarget).closest('.js-footer-category-container-target').toggleClass('is-open')
  }
})
