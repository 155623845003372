import './component.scss'
import './images/refosumu_floating_icon.png'
import './images/arrow.png'
import './images/blue_tile.png'

const $ = require('jquery')
$(window).on('scroll', function () {
  if ($(this).scrollTop() > 300) {
    $('.refosumu-v2-floating').fadeIn('slow')
  }
  else {
    $('.refosumu-v2-floating').fadeOut('slow')
  }
})
